import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import config from '../../data/SiteConfig'

export default function Eventimage() {
  return (
  <StaticQuery
  query = {graphql`
   query {
         image2: file(relativePath: { eq: "gallery/DSC_0890.JPG" }) {
            childImageSharp {
              fixed (width: 400 height:300){
                ...GatsbyImageSharpFixed
                }
                }
                }
         }
        `}
  render={data => (
    <span>
     <Img fixed={data.image2.childImageSharp.fixed} />
    </span>
  )}
  />
  )
  }
  
