import React from 'react'
import Eventimage from './Eventimage'
// import downloadFile from 'gallery/parents-guide.pdf' 
import config from '../../data/SiteConfig'
import UpcomingEventsImage from './UpcomingEventsImage'
import EventCarousel from './EventCarousel'
import SummerKickoff from './Summer-Kickoff'


const Annual = () => (
  <>
<h2>Upcoming Events</h2>
  <ul> 
<li>August 27: Community Serve Day
</li>
<li>
September 2-4: Cycling Overnight
</li>
<li>October 21-22: Shooting Sports 
Camp Pigott</li>
<li>Nov: Fort Service Project
State Park/Fort
</li>
</ul>
<h2>Recent Events</h2>
<ul>
<li>June 19: Summer Kickoff</li>
<li>June 2-4: Spring Food Drive</li>
<li>May 27-28: Mt. Rainier National Park Camping</li>
<li>April 28-30: Camporee, Camp Edward</li>
<li>December-January: Community Tree Recycling Campaign</li>
<li>October-November: Fall Food Drive</li>
<li>September 23-25: Service Project Campoout, Fort Flagler, Nordland WA</li>  
<li>September 18: Fall Recruiting Event, Blyth Park, Bothell WA</li>
<li>September 3-4: Labor Day Backpacking Camping Trip, Lake Valhalla, Leavenworth WA</li>
<li>August 2-10: Florida Sea Base High Adventure</li>
</ul>

<EventCarousel />
  <h2>Annual Events</h2>

   <p> Our troop is active in every season and traditionally participates in the following actvities during the year.
</p>


  <ul>
      <li>January: Tree recycling collection</li> 
      <li>February: Klondike/Winter Camping </li>
          <li>March: Shooting Sports </li>
          <li>April: Camping/Biking activity, Food drive</li> 
              <li>May: Service project and backpacking trip </li>
                  <li>June: Camporee </li>
                      <li>July: Summer Camp</li> 
                          <li>August: backpacking event</li> 
                              <li>Sept: Camping event</li>
                                  <li>Oct: Shooting Sports </li>
                                          <li>November: Food drive, service project, holiday wreath sale </li>
  <li>December: Tree recycling planning and envelope distribtion </li>

  </ul>
  

  </>

)

export default Annual
